import { Link } from "react-router-dom";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Spinner, Tabs } from "react-bootstrap";
import { Tab } from "@headlessui/react";
import { Table } from "../../components/Table";
import { Content, Header, Page } from "../../components/Page";

export function Regions() {
  const { data, isLoading } = useQuery({
    queryKey: ["regions"],
    queryFn: () => {
      return fetch(`/api/universe/regions/`).then((res) => res.json());
    },
  });

  const columns = useMemo(() => {
    return [
      {
        header: "Name",
        accessorKey: "name",
        cell: (cell) => {
          const value = cell.getValue();
          const id = cell.row.original.id;
          return <Link to={`/universe/region/${id}/`}>{value}</Link>;
        },
      },
    ];
  }, []);

  if (isLoading) {
    return (
      <div className={"p-4"}>
        <div className={"text-center"}>
          <Spinner animation={"grow"} role={"status"}>
            <span className={"visually-hidden"}>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return <Table rows={data} columns={columns} />;
}

export function Universe() {
  const [key, setKey] = useState("regions");

  return (
    <Page>
      <Content>
        <Header title={"Universe"} />
        <nav aria-label={"breadcrumb"}>
          <ol className={"breadcrumb"}>
            <li className={"breadcrumb-item"}>
              <Link to={"/universe/"}>Universe</Link>
            </li>
          </ol>
        </nav>
        <Tabs defaultActiveKey={"regions"} onSelect={setKey}>
          <Tab eventKey={"regions"} title={"Regions"}>
            {key === "regions" && <Regions />}
          </Tab>
        </Tabs>
      </Content>
    </Page>
  );
}
