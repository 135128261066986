import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { Table } from "../../components/Table";
import { TypeCell, typeLink } from "../../components/Cells";
import { Block, BlockFooter, BlockHeader } from "../../components/Block";
import { compactNumber } from "../../formatting";
import { Link, useNavigate } from "react-router-dom";
import { AssetsTable } from "../assets/Assets";
import { savePaste } from "../../mutations/paste";
import { Loading } from "../../components/Loading";

/**
 * Given a list of necessary skills, return a list of characters that can
 * perform them.
 *
 * @param skills
 * @returns {JSX.Element}
 * @constructor
 */
export function SkillsNeeded({ requiredSkills }) {
  const { data, isLoading } = useQuery({
    queryKey: ["skills_needed", "characters"],
    queryFn: () => {
      const url = new URL("/api/characters/", window.location.origin);
      url.searchParams.set("expand", ["skills", "skills.skill"].join(","));

      return fetch(url).then((res) => res.json());
    },
  });

  const skillColumns = [
    {
      header: "Skill",
      accessorKey: "skill",
      cell: (cell) => {
        const skill = cell.getValue();
        return <TypeCell id={skill.id} name={skill.name} />;
      },
    },
    {
      header: "Level",
      accessorKey: "level",
      meta: {
        align: "end",
      },
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Block>
        <BlockHeader>
          <div className={"text-center"}>
            <h3 className={"fs-5 mb-0"}>Required Skills</h3>
          </div>
        </BlockHeader>
        <Table columns={skillColumns} rows={requiredSkills} />
      </Block>
      <hr />
      <Block>
        <BlockHeader>
          <div className={"text-center"}>
            <h3 className={"fs-5 mb-0"}>Usable Characters</h3>
          </div>
        </BlockHeader>
        {data.length === 0 && (
          <div className={"alert alert-info"}>
            No characters can perform these skills.
          </div>
        )}
        {data.length > 0 && (
          <div
            className={"list-group overflow-y-auto"}
            style={{
              maxHeight: "400px",
            }}
          >
            {data.map((character) => {
              const skills = character.skills.reduce((acc, skill) => {
                acc[skill.skill.id] = skill;
                return acc;
              }, {});

              const missingSkills = requiredSkills.filter((skill) => {
                const characterSkill = skills[skill.skill.id];
                return (
                  !characterSkill || characterSkill.level < skill.requiredLevel
                );
              });

              return (
                <div className={"list-group-item"} key={character.id}>
                  <strong>{character.name}</strong>
                  <div className={"text-white-50"}>
                    {missingSkills.length === 0 ? (
                      <span className={"text-success"}>All skills met</span>
                    ) : (
                      <span className={"text-danger"}>
                        Missing {missingSkills.length} required skills
                      </span>
                    )}
                    {missingSkills.map((skill) => {
                      return (
                        <div key={skill.skill.id}>
                          <Link to={`/market/${skill.skill.id}`}>
                            {skill.skill.name}
                          </Link>
                          &nbsp;currently at&nbsp;
                          <span className={"text-info"}>
                            {skills[skill.skill.id]
                              ? skills[skill.skill.id].level
                              : 0}
                          </span>
                          &nbsp;of&nbsp;
                          <span className={"text-info"}>{skill.level}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Block>
    </>
  );
}

export function BlueprintInputs({ inputs }) {
  const columns = [
    {
      id: "item",
      header: "Item",
      accessorKey: "item",
      cell: (cell) => {
        const item = cell.getValue();
        return <TypeCell id={item.id} name={item.name} />;
      },
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
      meta: {
        align: "end",
      },
    },
    {
      id: "volume",
      header: "Volume (m³)",
      accessorKey: "item",
      meta: {
        align: "end",
      },
      cell: (cell) => {
        const item = cell.getValue();
        return compactNumber(item.volume * cell.row.original.quantity);
      },
    },
  ];

  return <Table columns={columns} rows={inputs} />;
}

export function ManufactureTab({ activity, blueprint }) {
  const [quantityMultiplier, setQuantityMultiplier] = useState(1);

  const navigate = useNavigate();
  const saveMutation = savePaste({
    onSuccess: (data) => {
      navigate(`/paste/${data.slug}`);
    },
  });

  const createAppraisal = useCallback(
    (e) => {
      e.target.disabled = true;

      saveMutation.mutate({
        content: activity.inputs
          .map((input) => {
            return `${input.quantity * quantityMultiplier} ${input.item.name}`;
          })
          .join("\n"),
        region: 10000002,
      });
    },
    [saveMutation, quantityMultiplier, activity],
  );

  if (!activity) {
    return (
      <div className={"alert alert-info"}>
        Not available for this blueprint.
      </div>
    );
  }

  return (
    <>
      <Block className={"mb-3"}>
        <BlockHeader>
          <div className={"text-center"}>
            <h3 className={"fs-5 mb-0"}>
              <Link to={`/market/${blueprint.item.id}`}>
                {blueprint.item.name}
              </Link>
            </h3>
            {activity.outputs.map((output) => {
              return (
                <div className={"fs-6"} key={output.item.id}>
                  Produces{" "}
                  <span className={"text-info"}>{output.quantity}</span>x{" "}
                  {typeLink(output.item.id, output.item.name)}
                </div>
              );
            })}
          </div>
        </BlockHeader>
        <BlueprintInputs inputs={activity.inputs} />
        <BlockFooter>
          <div className={"d-flex text-white-50"}>
            <div className={"flex-grow-1"}>
              Takes <span className={"text-info"}>{activity.time}</span> seconds
              to manufacture
            </div>
            <div className={"text-info"}>
              <button
                className={"btn btn-link btn-sm text-info p-0"}
                type={"button"}
              >
                Add to job planner
              </button>
            </div>
          </div>
        </BlockFooter>
      </Block>
      <div className={"row"}>
        <div className={"col-2 dark"}>
          <input
            type={"number"}
            className={"form-control"}
            value={quantityMultiplier}
            min={1}
            max={100000}
            onChange={(e) => setQuantityMultiplier(e.target.value)}
          />
        </div>
        <div className={"col-10"}>
          <button
            className={"btn btn-outline-info w-100 mb-3"}
            onClick={createAppraisal}
          >
            Create Appraisal from Blueprint
          </button>
        </div>
      </div>
      <SkillsNeeded requiredSkills={activity.skills} />
      <AssetsTable
        hideIfEmpty={true}
        filters={[
          {
            type: "type",
            value: {
              id: blueprint.item.id,
            },
            operator: "==",
          },
        ]}
      />
    </>
  );
}

/**
 * Display a blueprint and its inputs.
 *
 * @param item_id
 * @returns {JSX.Element}
 * @constructor
 */
export function Blueprint({ item_id }) {
  const [tool, setTool] = useState("manufacture");

  const { data, isLoading, isError } = useQuery({
    queryKey: ["blueprint", item_id],
    queryFn: () => {
      const url = new URL(
        `/api/industry/blueprints/output/${item_id}/`,
        window.location.origin,
      );
      url.searchParams.set(
        "expand",
        ["activities", "activities.skills", "item", "skill"].join(","),
      );

      return fetch(url).then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.json();
      });
    },
    retry: false,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className={"alert alert-info mt-3"}>No industrial data found.</div>
    );
  }

  return (
    <div className={"mt-3"}>
      <div className={"btn-group"}>
        <input
          type={"radio"}
          className={"btn-check"}
          name={"tool"}
          autoComplete={"off"}
          id={"tool-1"}
          checked={tool === "manufacture"}
          onChange={() => setTool("manufacture")}
        />
        <label className={"btn btn-outline-info"} htmlFor={"tool-1"}>
          Manufacture
        </label>
        <input
          type={"radio"}
          className={"btn-check"}
          name={"tool"}
          autoComplete={"off"}
          id={"tool-2"}
          checked={tool === "invention"}
          onChange={() => setTool("invention")}
        />
        <label className={"btn btn-outline-info"} htmlFor={"tool-2"}>
          Invention
        </label>
      </div>
      <div className={"mt-3"}>
        {tool === "manufacture" && (
          <ManufactureTab
            activity={data.activities.find((a) => a.activity_type === 1)}
            blueprint={data}
          />
        )}
        {tool === "invention" && (
          <ManufactureTab
            activity={data.activities.find((a) => a.activity_type === 8)}
            blueprint={data}
          />
        )}
      </div>
    </div>
  );
}
