import { Link, useParams } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Spinner, Tabs } from "react-bootstrap";
import { Tab } from "@headlessui/react";
import { AssetsTable } from "../assets/Assets";
import { Table } from "../../components/Table";
import {IconCell, security, toPercent} from "../../components/Cells";
import { humanizeRelativeDate } from "../../formatting";
import {Loading} from "../../components/Loading";
import {useStorage} from "../../hooks/useStorage";
import {applySortingToURL} from "../../components/FilterBuilder";

export function StationsTab({ region_id }) {
  const [url, setURL] = useState(undefined);
  const [sorting, setSorting] = useStorage("region.stations.sorting", [
    { id: "system.name", desc: false },
  ])

  const stationsURL = useMemo(() => {
    const params = new URLSearchParams();
    params.append("region_id", region_id);
    params.append("fields", [
      "id",
      "name",
      "order_count",
      "structure_type.name",
      "structure_type.id",
      "station_type",
      "system.name",
      "system.id",
    ].join(","));
    params.append("expand", [
      "structure_type",
      "system",
    ].join(","));

    if (sorting) {
      applySortingToURL(params, sorting);
    }

    return `/api/universe/stations/?${params.toString()}`;
  }, [region_id, sorting]);

  const { data: stations, isLoading } = useQuery({
    queryKey: ["stations", url || stationsURL],
    queryFn: () => {
      return fetch(url || stationsURL).then((res) => res.json());
    },
    staleTime: 30_000,
  });

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <div>
      <Table
        rows={stations?.results || []}
        isLoading={isLoading}
        sorting={sorting}
        setSorting={setSorting}
        manualSorting={true}
        columns={[
          {
            header: "System",
            accessorKey: "system.name",
            enableSorting: false,
            cell: (cell) => {
              const {system} = cell.row.original;
              return (
                <Link to={`/universe/system/${system.id}/`}>{system.name}</Link>
              );
            }
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: (cell) => {
              const {structure_type, id, name} = cell.row.original;
              return (
                <>
                  {structure_type && (
                    <IconCell id={structure_type.id} name={structure_type.name} className={"me-2"}/>
                  )}
                  {name}
                </>
              )
            }
          },
          {
            header: "Market Orders",
            accessorKey: "order_count",
            meta: {
              align: "end",
            },
          },
        ]}
      />
      <div className="my-4">
        {stations.previous && (
          <button
            className="btn btn-primary me-2"
            onClick={() => setURL(stations.previous)}
          >
            Previous
          </button>
        )}
        {stations.next && (
          <button
            className="btn btn-primary"
            onClick={() => setURL(stations.next)}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}

export function Systems({region_id}) {
  const {data, isLoading} = useQuery({
    queryKey: ["systems", region_id],
    queryFn: () => {
      return fetch(`/api/universe/regions/${region_id}/systems/`).then((res) => res.json());
    },
  });

  const columns = useMemo(() => {
    function costIndex(label, key) {
      return {
        header: label,
        accessorKey: key,
        meta: {
          align: "end",
        },
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <span
              className={[
                value < 0.05 ? "text-success" : "",
                value > 0.05 && value < 0.1 ? "text-warning" : "",
                value > 0.1 ? "text-danger" : "",
              ].join(" ")}
            >
              {toPercent(value)}
            </span>
          );
        },
      };
    }

    return [
      {
        header: "Name",
        accessorKey: "name",
        cell: (cell) => {
          const value = cell.getValue();
          const id = cell.row.original.id;
          return (
            <>
              {security(cell.row.original.security)}&nbsp;
              <Link to={`/universe/system/${id}/`}>{value}</Link>
            </>
          );
        },
      },
      costIndex("Manufacturing", "index_manufacturing"),
      costIndex("Copying", "index_copying"),
      costIndex("Invention", "index_invention"),
      costIndex("ME", "index_me"),
      costIndex("TE", "index_te"),
      costIndex("Research", "index_reaction"),
    ];
  }, []);

  if (isLoading) {
    return (
      <div className={"p-4"}>
        <div className={"text-center"}>
          <Spinner animation={"grow"} role={"status"}>
            <span className={"visually-hidden"}>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return <Table rows={data} columns={columns} />;
}

export function Region() {
  const { id } = useParams();
  const [key, setKey] = useState("systems");
  const { data, isLoading } = useQuery({
    queryKey: ["region", id],
    queryFn: () => {
      return fetch(`/api/universe/regions/${id}/`).then((res) => res.json());
    },
  });

  if (isLoading) {
    return (
      <div className={"p-4"}>
        <div className={"text-center"}>
          <Spinner animation={"grow"} role={"status"}>
            <span className={"visually-hidden"}>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className={"p-4 vh-100 d-flex flex-column"}>
      <div>
        <h1 className={"h3 mb-3 fw-normal"}>{data.name}</h1>
        <nav aria-label={"breadcrumb"}>
          <ol className={"breadcrumb"}>
            <li className={"breadcrumb-item"}>
              <Link to={"/universe/"}>Universe</Link>
            </li>
            <li className={"breadcrumb-item active"} aria-current={"page"}>
              {data.name}
            </li>
          </ol>
        </nav>
      </div>
      <div
        className={
          "flex-grow-1 d-flex flex-column overflow-y-auto tab-container"
        }
      >
        <Tabs defaultActiveKey={"systems"} onSelect={setKey}>
          <Tab eventKey={"systems"} title={"Systems"}>
            {key === "systems" && <Systems region_id={id} />}
          </Tab>
          <Tab eventKey={"assets"} title={"Assets"}>
            {key === "assets" && (
              <AssetsTable filters={[{ type: "location", value: id }]} />
            )}
          </Tab>
          <Tab eventKey={"stations"} title={"Stations"}>
            {key === "stations" && <StationsTab region_id={id} />}
          </Tab>
          <Tab eventKey={"info"} title={"Info"}>
            {key === "info" && (
              <div className={"p-4"}>
                <dl>
                  <dt>Description</dt>
                  <dd>{data.description}</dd>
                  <dt>Orders</dt>
                  <dd>
                    Market orders for this region last updated{" "}
                    <span className={"text-info"}>
                      {humanizeRelativeDate(data.orders_updated)}
                    </span>
                    .
                  </dd>
                  {data.priority ? (
                    <>
                      <dt>Priority</dt>
                      <dd>
                        This region has a boosted priority and will be updated
                        before others.
                      </dd>
                    </>
                  ) : null}
                </dl>
              </div>
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
