import React, { useState, useCallback, useMemo } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { compactNumber, humanizeRelativeDate } from "../../formatting";
import { CompactStationLocation, priceCell } from "../../components/Cells";
import * as d3 from "d3";
import { Table } from "../../components/Table";
import { useQuery } from "@tanstack/react-query";
import { Stat, Stats } from "../../components/Page";
import {applyFiltersToURL, FilterBuilder} from "../../components/FilterBuilder";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {useStorage} from "../../hooks/useStorage";

const useOrders = ({marketId, filters}) => {
  const url = useMemo(() => {
    const params = new URLSearchParams();
    params.set("type_id", marketId);
    if (filters) applyFiltersToURL(params, filters);
    return `/api/types/${marketId}/orders/?${params}`;
  }, [marketId, filters]);

  const { data: userOrders, isLoading: userOrdersIsLoading } = useQuery({
    queryKey: ["orders", "me", marketId],
    queryFn: () =>
      fetch(`/api/me/orders/?type_id=${marketId}`)
        .then((res) => res.json())
        .then((data) => Object.fromEntries(data.map((order) => [order.id, order]))),
  });

  const { data: allOrders, isLoading: ordersIsLoading } = useQuery({
    queryKey: ["orders", url],
    queryFn: () => fetch(url).then((res) => res.json()),
  });

  return {
    userOrders,
    allOrders,
    isLoading: userOrdersIsLoading || ordersIsLoading,
  };
};

const OrderPopup = ({ userOrder, order }) => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Popover>
        <Popover.Body>
          <dl>
            <dt>Issued By</dt>
            <dd>
              <img
                src={`https://images.evetech.net/characters/${userOrder.character.id}/portrait?size=32`}
                alt={userOrder.character.name}
                className="me-2"
              />
              {userOrder.character.name}
            </dd>
            <dt>Issued</dt>
            <dd>{humanizeRelativeDate(userOrder.issued)}</dd>
            <dt>Expires</dt>
            <dd>{humanizeRelativeDate(userOrder.expires)}</dd>
            <dt>Quantity</dt>
            <dd>
              {userOrder.volume_remain} / {userOrder.volume_total}
            </dd>
          </dl>
        </Popover.Body>
      </Popover>
    }
  >
    <span className="text-success">{priceCell(order.price)}</span>
  </OverlayTrigger>
);

export function OrdersTab({ marketId }) {
  const [filters, setFilters] = useStorage("market.orders.filters", []);
  const [sellSorting, setSellSorting] = useState([{ id: "price", desc: false }]);
  const [buySorting, setBuySorting] = useState([{ id: "price", desc: true }]);
  const { userOrders, allOrders, isLoading } = useOrders({marketId, filters});

  const rowClasses = useCallback(
    (row) => (userOrders && userOrders[row.original.id] ? "table-success" : null),
    [userOrders]
  );

  const orderPopup = useCallback(
    (order) => 
      userOrders && userOrders[order.id] 
        ? <OrderPopup userOrder={userOrders[order.id]} order={order} />
        : priceCell(order.price),
    [userOrders]
  );

  const common_columns = useMemo(
    () => [
      {
        header: "Region",
        accessorFn: (row) => row.region.name,
      },
      {
        header: "Quantity",
        accessorKey: "volume_remain",
        sortingFn: "alphanumeric",
        enableSorting: true,
        meta: { align: "end" },
        cell: (cell) => compactNumber(cell.getValue()),
      },
      {
        header: "Price",
        accessorKey: "price",
        sortingFn: "alphanumeric",
        enableSorting: true,
        meta: { align: "end" },
        cell: (cell) => orderPopup(cell.row.original),
      },
      {
        header: "Location",
        accessorKey: "location",
        cell: (cell) => <CompactStationLocation location={cell.getValue()} />,
      },
    ],
    [orderPopup]
  );

  const buyer_columns = useMemo(
    () => [
      ...common_columns,
      {
        header: "Range",
        accessorKey: "range",
        cell: (cell) => {
          const range = cell.getValue();
          return range === "solarsystem" ? "System" : range.charAt(0).toUpperCase() + range.slice(1);
        },
      },
      {
        header: "Min Volume",
        accessorKey: "min_volume",
        sortingFn: "alphanumeric",
        meta: { align: "end" },
      },
    ],
    [common_columns]
  );

  const buy_orders = useMemo(() => allOrders?.filter((order) => order.is_buy_order) || [], [allOrders]);
  const sell_orders = useMemo(() => allOrders?.filter((order) => !order.is_buy_order) || [], [allOrders]);

  return (
    <>
      <Stats>
        <Stat title="Sell Volume" value={compactNumber(sell_orders.reduce((a, b) => a + b.volume_remain, 0))} />
        <Stat title="Buy Volume" value={compactNumber(buy_orders.reduce((a, b) => a + b.volume_remain, 0))} />
        <Stat title="Average Sell" value={compactNumber(d3.quantile(sell_orders.map((order) => order.price), 0.05))} />
        <Stat title="Average Buy" value={compactNumber(d3.quantile(buy_orders.map((order) => order.price), 0.95))} />
      </Stats>
      <FilterBuilder
        filters={filters}
        onChange={setFilters}
        allowedFilters={{
          location: {
            label: "Location",
            operators: ["==", "!="],
            component: AutocompleteInput,
            props: {
              type: "location",
            },
          },
          volume_remain: {
            label: "Quantity",
            operators: ["<<", ">>", "<=", ">="],
          },
          price: {
            label: "Price",
            operators: ["<<", ">>", "<=", ">="],
          }
        }}
      />
      <h2 className="fs-5 pt-2">Sellers</h2>
      <div className="table-container table-short">
        <Table
          defaultColumn={{
            enableSorting: false,
          }}
          isLoading={isLoading}
          rows={sell_orders || []}
          columns={common_columns}
          rowClasses={rowClasses}
          sorting={sellSorting}
          setSorting={setSellSorting}
        />
      </div>
      <h2 className="fs-5 pt-4">Buyers</h2>
      <div className="table-container table-short">
        <Table
          defaultColumn={{
            enableSorting: false,
          }}
          isLoading={isLoading}
          rows={buy_orders || []}
          columns={buyer_columns}
          rowClasses={rowClasses}
          sorting={buySorting}
          setSorting={setBuySorting}
        />
      </div>
    </>
  );
}